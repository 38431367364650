<template>
  <div class="flex flex-col w-full items-center py-7">
    <h5
      :style="
        props.titleCustomSize
          ? { 'font-size': props.titleCustomSize + 'px' }
          : {}
      "
      class="flex gap-0.5 items-center"
    >
      {{ props.title }}
      <IconWrapper
        v-if="props.downloadFileName"
        icon="download"
        hover="hover:text-success"
        class="cursor-pointer"
        @click="downloadChart(chartRef, props)"
      />
    </h5>
    <v-chart ref="chartRef" :option="option" class="chartItem" autoresize />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import { downloadChart } from '@/utils/chart-download';

const props = defineProps({
  chartData: {},
  title: {
    type: String,
    default: '',
  },
  downloadTitleFontSize: {
    type: Number,
    default: 100,
  },
  downloadFileName: String,
});

const chartRef = ref(null);

const option = computed(() => {
  const data = props.chartData;

  const transformedData = {};

  Object.keys(data).forEach((category) => {
    data[category].forEach((item) => {
      if (!transformedData[item.name]) {
        transformedData[item.name] = [];
      }
      transformedData[item.name].push(item);
    });
  });

  // Sort the series keys alphabetically
  const sortedSeriesKeys = Object.keys(transformedData).sort((a, b) =>
    a.localeCompare(b),
  );

  const categories = Object.keys(props.chartData);
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: (params) => {
        let result = '';
        params.forEach((item) => {
          // filter 0 values
          if (item.value === 0) {
            return;
          }
          result += `${item.marker} ${
            item.seriesName
          }: ${item.value.toLocaleString('de-DE')}<br/>`;
        });
        return result;
      },
    },
    legend: {
      // show only items in the legend that are visible in the chart
      data: Object.keys(transformedData)
        .filter((key) => {
          return transformedData[key].some((item) => item.value > 0);
        })
        .sort((a, b) => a.localeCompare(b)),
      bottom: 20,
      itemWidth: 12,
      itemHeight: 12,
    },
    grid: { bottom: '15%', top: '15%', containLabel: true },
    xAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        formatter: (value) => {
          return value.toLocaleString('de-DE');
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: categories,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },

    series: sortedSeriesKeys.map((key) => {
      return {
        name:
          transformedData[key].length > 0 ? transformedData[key][0].name : '',
        type: 'bar',
        stack: 'stack',
        data: transformedData[key].map((item) => item.value),
        itemStyle: {
          color:
            transformedData[key].length > 0
              ? transformedData[key][0].color
              : '',
        },
        barWidth: 35,
      };
    }),
  };
});
</script>
