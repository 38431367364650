export function downloadChart(chartRef, props) {
  if (!props.downloadFileName) return;

  const chartImageSrc = chartRef.getDataURL({
    pixelRatio: 4, // Increased pixel ratio for higher resolution
    backgroundColor: '#fff',
  });

  const img = new Image();
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set dimensions of the canvas to accommodate larger title and padding
    const titleHeight = props.downloadTitleFontSize * 3; // Increased height for title area
    canvas.width = img.width;
    canvas.height = img.height + titleHeight;

    // Draw the title background
    ctx.fillStyle = 'white'; // White background for the title
    ctx.fillRect(0, 0, canvas.width, titleHeight);

    // Set up the title font and style
    ctx.font = `600 ${props.downloadTitleFontSize}px 'Borna', sans-serif`;
    // Font weight and family
    ctx.textAlign = 'center';
    ctx.fillStyle = 'black'; // Font color
    ctx.fillText(
      props.downloadTitle || props.title,
      canvas.width / 2,
      props.downloadTitleFontSize * 2,
    );

    // Draw the chart image below the title
    ctx.drawImage(img, 0, titleHeight);

    // Create the final image URL and download it
    const finalImgUrl = canvas.toDataURL('image/png');
    const anchor = document.createElement('a');
    anchor.href = finalImgUrl;
    anchor.download = `${props.downloadFileName}.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  img.src = chartImageSrc;
}
