<template>
  <v-chart ref="chartRef" :option="option" class="h-96" autoresize />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
  chartData: Object,
  chartTitle: String,
  multiply: Number,
  customMinHeight: Boolean,
  showTotal: {
    type: Boolean,
    default: true,
  },
});

const chartRef = ref(null);

const emits = defineEmits(['chartRef']);

onMounted(() => emits('chartRef', chartRef.value));

const option = computed(() => {
  const rawData = props.chartData;
  const years = rawData.map((d) => d.year);
  const seriesData = rawData.reduce((acc, d) => {
    d.data.forEach((ht) => {
      const existingSeries = acc.find((s) => s.name === ht.name);
      if (existingSeries) {
        existingSeries.data.push(
          props.multiply ? ht.value * props.multiply : ht.value,
        );
      } else {
        acc.push({
          name: ht.name,
          type: 'bar',
          stack: 'total',
          data: [props.multiply ? ht.value * props.multiply : ht.value],
          itemStyle: {
            color: ht.color,
          },
          barWidth: 50,
        });
      }
    });
    return acc;
  }, []);

  return {
    title: {
      text: props.chartTitle,
      textStyle: {
        color: 'black',
        textAlign: 'center',
        lineHeight: 25,
        fontFamily: 'Borna, sans-serif',
        fontWeight: 500,
        fontSize: 18,
      },
      left: 'center',
      top: '25px',
    },
    grid: { bottom: '15%', top: '20%', containLabel: true },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        let result = '';
        let total = 0;
        let nonZeroCount = 0; // Counter for non-zero items

        params.forEach((item) => {
          if (item.value !== 0) {
            result += `${item.marker} ${item.seriesName}: ${Math.floor(
              item.value,
            ).toLocaleString('de-DE')}<br/>`;
            total += item.value;
            nonZeroCount++; // Increment only for non-zero values
          }
        });

        // Check if there are more than one non-zero items and showTotal is true
        if (props.showTotal && nonZeroCount > 1 && total > 0) {
          result += `<b>Total: ${Math.floor(total).toLocaleString('de-DE')}</b>`;
        }

        return result;
      },
    },
    legend: {
      data: seriesData
        .filter((s) => s.data.some((d) => d > 0))
        .map((s) => s.name),
      bottom: 0,
      itemWidth: 12,
      itemHeight: 12,
    },
    xAxis: {
      type: 'category',
      data: years,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        formatter: (value) => value.toLocaleString('de-DE'),
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    series: seriesData,
  };
});
</script>
