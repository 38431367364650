<template>
  <div v-if="data" class="flex flex-col justify-center gap-4">
    <div class="data-row-dashboard">
      <h3 class="data-row-title">Wärmepotenziale in GWh/a</h3>
      <div
        class="data-row-items h-[270px]"
        :class="
          getPowerTypes('heat').length === 0 ? 'grid-cols-1' : 'grid-cols-2'
        "
      >
        <DoughnutChart
          title="In Prozent"
          :chart-data="getPowerTypes('heat')"
          title-inside="Wärmepotenzial"
          :title-inside-l-h="20"
          title-top="80"
          :custom-radius="['37%', '47%']"
          chart-top="-55"
          chart-unit="GWh/a"
          :multiply="0.001"
          download-file-name="waermepotenziale_in_prozent"
          download-title="Wärmepotenziale in Prozent"
        />
        <bar-chart
          v-if="getPowerTypes('heat').length > 0"
          title="In absoluten Zahlen"
          :chart-data="getPowerTypes('heat')"
          :filter-zero-values="false"
          :custom-min-height="38"
          :multiply="0.001"
          download-file-name="waermepotenziale_in_absoluten_zahlen"
          download-title="Wärmepotenziale in absoluten Zahlen"
        />
      </div>
    </div>
    <div class="data-row-dashboard">
      <h3 class="data-row-title">Strompotenziale in GWh/a</h3>
      <div
        class="data-row-items h-[270px]"
        :class="
          getPowerTypes('electricity').length === 0
            ? 'grid-cols-1'
            : 'grid-cols-2'
        "
      >
        <DoughnutChart
          title="In Prozent"
          :chart-data="getPowerTypes('electricity')"
          title-inside="Strompotenzial"
          :title-inside-l-h="20"
          title-top="80"
          :custom-radius="['37%', '47%']"
          chart-top="-55"
          chart-unit="GWh/a"
          :multiply="0.001"
          download-file-name="strompotenziale_in_prozent"
          download-title="Strompotenziale in Prozent"
        />
        <bar-chart
          v-if="getPowerTypes('electricity').length > 0"
          title="In absoluten Zahlen"
          :chart-data="getPowerTypes('electricity')"
          :filter-zero-values="false"
          :custom-min-height="38"
          :multiply="0.001"
          download-file-name="strompotenziale_in_absoluten_zahlen"
          download-title="Strompotenziale in absoluten Zahlen"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import BarChart from '@/apps/analysis-dashboard/charts/barChart.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

function getPowerTypes(type) {
  if (props.data) return props.data.filter((i) => i.type === type);
  return [];
}
</script>
