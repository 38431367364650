<template>
  <div class="flex flex-col w-full items-center py-7 h-full">
    <h5
      :style="
        props.titleCustomSize
          ? { 'font-size': props.titleCustomSize + 'px' }
          : {}
      "
      class="flex gap-0.5 items-center mb-7"
    >
      {{ props.title }}
      <IconWrapper
        v-if="props.downloadFileName"
        icon="download"
        hover="hover:text-success"
        class="cursor-pointer"
        @click="downloadChart(chartRef, props)"
      />
    </h5>
    <v-chart ref="chartRef" :option="option" autoresize :style="chartStyle" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import { downloadChart } from '@/utils/chart-download';

const chartRef = ref(null);

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  multiply: {
    type: Number,
    default: null,
  },
  customMinHeight: {
    type: Number,
  },
  gridTop: {
    type: String,
    default: 'center',
  },
  gridBottom: {
    type: String,
    default: '',
  },
  filterZeroValues: {
    type: Boolean,
    default: true,
  },
  decimalPlaces: {
    type: Number,
    default: 1,
  },
  downloadTitle: String,
  downloadTitleFontSize: {
    type: Number,
    default: 65,
  },
  downloadFileName: String,
});

const chartStyle = computed(() => {
  if (props.customMinHeight && sortedChartData.value.length !== 0) {
    return (
      'height:' + sortedChartData.value.length * props.customMinHeight + 'px'
    );
  }
  return '';
});

const sortedChartData = computed(() => {
  let data = props.chartData.map((item) => ({
    ...item,
    value: props.multiply ? item.value * props.multiply : item.value,
  }));

  // filter 0 values and sort by name
  data = props.filterZeroValues
    ? data.filter((item) => item.value !== 0)
    : data;
  data.sort((b, a) => a.name.localeCompare(b.name));

  return data;
});

const option = computed(() => {
  return {
    grid: {
      left: 'center',
      top: '0px',
      bottom: '0px',
      containLabel: true,
      width: '70%',
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: sortedChartData.value.map((item) => item.name),
      axisLabel: {
        fontSize: 12,
        color: 'black',
        fontFamily: 'Inter, sans-serif',
        align: 'right',
        fontWeight: 500,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        data: sortedChartData.value.map((item) => ({
          ...item,
          emphasis: {
            itemStyle: {
              color: item.color,
            },
          },
        })),
        label: {
          show: true,
          position: 'right',
          offset: [0, -0.5],
          formatter: function (params) {
            const value = Math.round(params.data.value * 100) / 100;
            if (value < 1) {
              return value.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              return value.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          },
          color: 'black',
          fontWeight: '600',
          fontSize: 12,
        },
        barWidth: 24,
        itemStyle: {
          color: function (params) {
            const colorList = sortedChartData.value.map((item) => item.color);
            return colorList[params.dataIndex];
          },
        },
      },
    ],
  };
});
</script>
