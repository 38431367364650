<template>
  <div v-if="data" class="flex flex-col justify-center gap-4">
    <div class="data-row-dashboard flex-row justify-between p-2.5 gap-5">
      <div class="w-full max-w-[45%]">
        <DoughnutChart
          download-file-name="anzahl_netzcluster"
          :chart-data="chartData"
          title-inside="Netzcluster"
          title="Anzahl Netzcluster"
          :title-inside-l-h="20"
          :custom-radius="['50%', '65%']"
        />
      </div>

      <div class="relative w-full">
        <IconWrapper
          icon="download"
          fill="text-color1 hover:text-title-color1"
          :size="32"
          class="absolute right-2 top-3 cursor-pointer"
          @click="downloadCards(cards)"
        />
        <div class="grid grid-cols-2 gap-1 min-w-[55%] w-full">
          <div
            v-for="card in cards"
            :key="card"
            class="text-blue-grey-600 py-3 px-2 flex flex-col items-center gap-6 bg-white rounded-[3px] standard-elevation-0-dark"
          >
            <div class="flex flex-col gap-2 items-center">
              <IconWrapper :icon="card.icon" :size="32" />
              <div class="body-1 text-center">{{ card.title }}</div>
            </div>
            <h3>{{ formatNumber(card.value) + ' ' + card.unit }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import IconHeatWavesCircleOutlined from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHeatWavesCircleOutlined.vue';
import IconHeatWavesBoxPoint from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHeatWavesBoxPoint.vue';
import IconHouseLineFilled from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHouseLineFilled.vue';
import IconHeatWavesLineFilled from '@/components/storybook/src/assets/icons/custom/energy-grid/IconHeatWavesLineFilled.vue';
import { downloadCards, formatNumber } from '@/apps/analysis-dashboard/utils';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

const cards = computed(() => {
  return [
    {
      title: 'Wärmebedarf',
      value: props.data?.sum_heat_demand / 1000,
      unit: 'GWh/a',
      icon: IconHeatWavesCircleOutlined,
    },
    {
      title: 'Wärmeliniendichte',
      value: props.data?.heat_density,
      unit: 'kWh/m',
      icon: IconHeatWavesBoxPoint,
    },
    {
      title: 'Länge Hausanschlussleitungen',
      value: props.data?.length_building,
      unit: 'm',
      icon: IconHouseLineFilled,
    },
    {
      title: 'Länge Verteilnetz',
      value: props.data?.length_distribution,
      unit: 'm',
      icon: IconHeatWavesLineFilled,
    },
    {
      title: 'Investitionskosten',
      value: props.data?.sum_invest_total / 1000000,
      unit: 'Mio. €',
      icon: 'euro',
    },
    // {
    //   title: 'Angenommene Erzeugungskosten',
    //   value: props.data?.generation_cost,
    //   unit: '€/MWh',
    //   icon: 'euro',
    // },
  ];
});

const chartData = computed(() => {
  return props.data
    ? [
        {
          name: 'Deckungsbeitrag >= 0',
          color: '#00B979',
          value: props.data.count_positive_margin,
        },
        {
          name: 'Deckungsbeitrag < 0',
          color: '#BC0606',
          value: props.data.count_negative_margin,
        },
      ]
    : [];
});
</script>
