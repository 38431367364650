export function formatNumber(value) {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function downloadCards(cards) {
  const { ctx, canvas } = createCanvasContext();
  const headers = ['Kategorie', 'Wert', 'Einheit'];
  const table = {
    xStartCoordinate: 50,
    yStartCoordinate: 50,
    rows: cards.length + 1, // 1 header + 5 data rows
    cols: headers.length,
    cellWidth: 250,
    cellHeight: 60,
    headers: headers,
  };

  drawTableShadow(ctx, table);
  drawHeaders(ctx, table);
  drawTableRows(ctx, table, cards);

  downloadTable(canvas);
}

function createCanvasContext() {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', '850');
  canvas.setAttribute('height', '600');
  const ctx = canvas.getContext('2d');

  ctx.strokeStyle = '#000';
  ctx.textAlign = 'left';
  ctx.textBaseline = 'middle';

  return { ctx, canvas };
}

function drawTableShadow(
  ctx,
  { xStartCoordinate, yStartCoordinate, cols, rows, cellWidth, cellHeight },
) {
  ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
  ctx.shadowBlur = 10;
  ctx.shadowOffsetX = 5;
  ctx.shadowOffsetY = 5;

  // Draw table background (to apply shadow)
  ctx.fillRect(
    xStartCoordinate,
    yStartCoordinate,
    cols * cellWidth,
    rows * cellHeight,
  );

  // Remove shadow for following drawings
  ctx.shadowColor = 'rgba(0, 0, 0, 0)';
}

function drawHeaders(
  ctx,
  { xStartCoordinate, yStartCoordinate, cols, cellWidth, cellHeight, headers },
) {
  // Draw header background
  ctx.fillStyle = '#fff';
  ctx.fillRect(
    xStartCoordinate,
    yStartCoordinate,
    cols * cellWidth,
    cellHeight,
  );

  // Draw header text
  ctx.font = 'bold 16px Arial';
  ctx.fillStyle = '#000';
  headers.forEach((headerText, i) => {
    ctx.fillText(
      headerText,
      xStartCoordinate + (i + 0.1) * cellWidth,
      yStartCoordinate + cellHeight / 2,
    );
  });
}

function drawTableRows(
  ctx,
  { xStartCoordinate, yStartCoordinate, cols, cellWidth, cellHeight },
  cards,
) {
  cards.forEach((item, rowIndex) => {
    for (let col = 0; col < cols; col++) {
      const cellX = xStartCoordinate + col * cellWidth;
      const cellY = yStartCoordinate + (rowIndex + 1) * cellHeight;

      if (rowIndex % 2 === 0) {
        ctx.fillStyle = '#f9f9f9';
      } else {
        ctx.fillStyle = '#fff';
      }
      ctx.fillRect(cellX, cellY, cellWidth, cellHeight);

      let text = '';
      switch (col) {
        case 0:
          text = item.title;
          break;
        case 1:
          text = item.value;
          break;
        case 2:
          text = item.unit;
          break;
      }

      ctx.font = '16px Arial';
      ctx.fillStyle = '#000';
      ctx.fillText(
        formatNumber(text),
        xStartCoordinate + (col + 0.1) * cellWidth,
        cellY + cellHeight / 2,
      );
    }
  });
}

function downloadTable(canvas) {
  const link = document.createElement('a');
  link.download = 'table.png';
  link.href = canvas.toDataURL('image/png');
  link.click();
}
