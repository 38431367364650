import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Login from '@/apps/auth/LoginPage.vue';
import ForgotPage from '@/apps/auth/ForgotPage.vue';
import MapView from '@/views/MapView.vue';
import LandingPage from '@/apps/landing-page/LandingPage.vue';
import ResetPage from '@/apps/auth/ResetPage.vue';
import UserRegistration from '@/apps/auth/UserRegistration.vue';
import profile from '@/views/profile/ProfilePage.vue';
import documentFiling from '@/views/documentFiling.vue';
import DataSpace from '@/views/DataSpace.vue';
import ScenarioView from '@/apps/scenarios/ScenarioView.vue';
import ScenarioFrameView from '@/features/scenario-frame/ScenarioFrameView.vue';
import EconomicEfficiency from '@/apps/economic-efficiency/EconomicEfficiency.vue';
import PremisesPage from '@/apps/premises/PremisesPage.vue';
import AnalysisDashboard from '@/apps/analysis-dashboard/DashboardWrapper.vue';
import MaterialConceptList from '@/apps/material-concept/MaterialConceptList.vue';
import { infraModules } from '@/configs/infra-modules';
import { useModuleStore } from '@/configs/module-store';
import TaskManager from '@/apps/task-manager/TaskManager.vue';
import ProjectAreasOverview from '@/apps/projects/ProjectAreasOverview.vue';
import BuildingUpdate from '@/features/building-versioning/BuildingUpdate.vue';
import BuildingVersioning from '@/features/building-versioning/BuildingVersioning.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import CustomAnalysis from '@/features/custom-analysis/CustomAnalysis.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        layout: 'Auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPage',
      component: ForgotPage,
      meta: {
        layout: 'Auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/reset-password/',
      name: 'ResetPage',
      component: ResetPage,
      meta: {
        layout: 'Auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/',
      name: 'landingURL',
      component: LandingPage,
      meta: {
        requiresAuth: true,
        title: 'Startseite',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/map/:scenarioID?',
      name: 'mapView',
      component: MapView,
      meta: {
        requiresAuth: true,
        hide_breadcrumb: 1,
        hide_padding: 1,
        preDispatchActions: ['map/GET_CUSTOM_TILESETS'],
      },
    },
    {
      path: '/material-concept',
      name: 'MaterialConceptList',
      component: MaterialConceptList,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        hide_breadcrumb: 0,
        title: 'Materialkonzept',
        module: infraModules.scenarioFrames,
      },
    },
    {
      path: '/analysis-dashboard',
      name: 'Dashboards',
      component: AnalysisDashboard,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Auswertungen & Dashboard',
        module: infraModules.dashboard,
      },
    },
    {
      path: '/documents',
      name: 'documents',
      component: documentFiling,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Dokumentenablage',
      },
    },
    {
      path: '/data-space',
      name: 'DataSpace',
      component: DataSpace,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Datenbereich',
      },
      redirect: { path: '/data-space/task-manager' },
      children: [
        {
          path: 'task-manager',
          name: 'TaskManager',
          component: TaskManager,
          meta: { title: 'Task Manager' },
          beforeEnter: (to, from, next) => goToRightPath(to, from, next),
        },
        {
          path: 'update',
          name: 'BuildingUpdate',
          component: BuildingUpdate,
          meta: { title: 'Gebäudedaten aktualisieren' },
        },
        {
          path: 'reset',
          name: 'BuildingReset',
          component: BuildingVersioning,
          meta: { title: 'Gebäudedaten zurücksetzen' },
        },
        {
          path: 'custom-analysis',
          name: 'CustomAnalysis',
          component: CustomAnalysis,
          meta: { title: 'Eigene Analyseergebnisse' },
        },
      ],
    },

    {
      path: '/economic-efficiency/:wireId?',
      name: 'EconomicEfficiency',
      component: EconomicEfficiency,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Wirtschaftlichkeitsrechner',
        module: infraModules.wire,
      },
    },
    {
      path: '/scenarios',
      name: 'scenarios',
      component: ScenarioView,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Szenarien',
        module: infraModules.scenarios,
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectAreasOverview,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Projekte',
        module: infraModules.projectPlaner,
      },
    },
    {
      path: '/scenario-frame',
      name: 'scenarioFrame',
      component: ScenarioFrameView,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Szenariorahmen',
        module: infraModules.scenarioFrames,
      },
    },
    {
      path: '/premises',
      name: 'premises',
      component: PremisesPage,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Prämissen',
        module: infraModules.wire,
      },
    },

    {
      path: '/registration/:token',
      name: 'dataMask',
      component: UserRegistration,
      meta: {
        layout: 'Auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: profile,
      meta: {
        requiresAuth: true,
        layout: 'Page',
        title: 'Mein Profil',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: NotFoundPage,
      meta: {
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
  ],
});

function validateTaskManagerCategory(to, next) {
  const validCategories = ['Upload', 'Download'];
  if (!validCategories.includes(to.query.category)) {
    next({
      path: to.path,
      query: { category: 'Upload' },
    });
  } else {
    next();
  }
}

function goToRightPath(to, from, next) {
  const isSuperuser = store.getters['auth/profil']?.isSuperUser ?? false;
  if (isSuperuser) {
    validateTaskManagerCategory(to, next); // superusers go to Taskmanager
  } else {
    next({ path: '/data-space/update' }); // Non-superusers go to Update
  }
}

/**
 * Before each route updated
 */
router.beforeEach(async (to, from) => {
  if (to.meta.requiresAuth) {
    const checkClientHashPromise = store.dispatch('auth/CHECK_CLIENT_HASH');
    const getNotificationPromise = store.dispatch('auth/GET_NOTIFICATIONS');
    const getProfileDataPromise = store
      .dispatch('auth/GET_PROFILE_DATA')
      .then(() => {
        to.meta.previousPath = from.path;
        let forbidden = false;

        const metaRole = to.meta.requiresRole;
        const moduleStore = useModuleStore();

        if (!moduleStore.isActive(to.meta.module)) forbidden = true;

        if (metaRole && !store.state.auth.user[metaRole]) forbidden = true;
        if (forbidden) router.push({ name: 'landingURL' });
      })
      .catch(() => {
        router.push({
          name: 'Login',
          query: { from: window.location.pathname },
        });
      });

    await Promise.all([
      checkClientHashPromise,
      getNotificationPromise,
      getProfileDataPromise,
    ]);
  }
  if (to.meta.preDispatchActions) {
    await Promise.all(
      to.meta.preDispatchActions.map((action) => store.dispatch(action)),
    );
  }
});

export default router;
