<template>
  <div
    v-if="canChangeRelease && releaseStatusLabel === 'Offen'"
    class="flex flex-col gap-5 pt-2.5"
  >
    <div class="p-4 flex bg-subtle w-full rounded-md">
      <IconWrapper icon="info" class="mr-2" />
      <p class="body-1 leading-6">Es liegt keine Freigabeaufforderung vor</p>
    </div>
  </div>
  <div
    v-if="releaseStatusLabel !== 'Offen'"
    :class="`flex flex-col gap-5 ${statusDisplay.containerClasses}`"
  >
    <div
      :class="`p-4 flex w-full rounded-md ${statusDisplay.infoBoxBackground}`"
    >
      <IconWrapper
        :icon="statusDisplay.icon"
        class="mr-2"
        :fill="statusDisplay.color"
      />
      <p :class="`body-1 leading-6 ${statusDisplay.color}`">
        {{ statusDisplay.decisionText }}
      </p>
    </div>

    <div
      v-if="canChangeRelease || releaseStatusLabel === 'Angefordert'"
      :class="`grid grid-cols-2 gap-5 items-start pt-2.5`"
    >
      <h5 class="text-title-neutral">Freigabe angefordert durch</h5>
      <span class="text-neutral body-1 leading-4">{{
        projectData.release_requested_by
      }}</span>
      <h5 class="text-title-neutral">Freigabe angefordert am</h5>
      <span class="text-neutral body-1 leading-4">{{
        projectData.release_requested_at
      }}</span>
    </div>

    <div
      v-if="
        releaseStatusLabel === 'Genehmigt' || releaseStatusLabel === 'Abgelehnt'
      "
      class="grid grid-cols-2 gap-5 items-start pt-2.5"
    >
      <h5 class="text-title-neutral">
        Freigabe {{ statusDisplay.decisionVerb }} durch
      </h5>
      <span class="text-neutral body-1 leading-4">{{
        projectData.release_status_changed_by
      }}</span>
      <h5 class="text-title-neutral">
        Freigabe {{ statusDisplay.decisionVerb }} am
      </h5>
      <span class="text-neutral body-1 leading-4">{{
        projectData.release_status_changed_at
      }}</span>
      <h5 class="text-title-neutral">Kommentar</h5>
      <span class="text-neutral body-1 leading-4">{{
        projectData.release_comment
      }}</span>
    </div>

    <div
      v-if="releaseStatusLabel === 'Genehmigt'"
      class="flex gap-2.5 justify-end py-2.5"
    >
      <ButtonEl icon="map" icon-type="round" @click="goToMap" />
      <ButtonEl
        text="Excel-Bericht"
        icon="file_download"
        icon-type="round"
        @click="downloadExcel"
      />
    </div>
  </div>
</template>
<script setup>
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import { computed } from 'vue';
import { statusMapping } from '@/apps/economic-efficiency/status-data';
import { downloadFile } from '@/utils/axiosHelper';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const props = defineProps({
  projectData: {
    type: Object,
  },
  releaseStatusLabel: {
    type: String,
  },
  canChangeRelease: {
    type: Boolean,
  },
});

const store = useStore();
const router = useRouter();

const statusDisplay = computed(
  () => statusMapping[props.projectData.release_status].display,
);

function goToMap() {
  localStorage.setItem('denominator', 'scenario');
  localStorage.setItem('municipality', 'all');
  localStorage.setItem('scenario', props.projectData.scenario_id);
  store.commit('heatProject/SET_LOAD_PROJECTS_ON_MAPLOAD', [
    props.projectData.heat_project,
  ]);
  router.push(`/map/${props.projectData.scenario_id}`);
}

function downloadExcel() {
  const url = `/api/heatprojects/wi-re/wi-re/${props.projectData.id}/download-excel/`;
  downloadFile(url);
}
</script>
