<template>
  <div v-if="data" class="flex flex-col justify-center gap-4">
    <div class="data-row-dashboard">
      <div class="data-row-items grid-cols-2 h-96">
        <DoughnutChart
          download-file-name="gebaeude_nach_baualtersklassen"
          :chart-data="constructionYearCount"
          :title="`Gebäude nach\nBaualtersklassen`"
          title-inside="Gebäude"
          :title-inside-l-h="20"
          class="pb-0"
        />
        <DoughnutChart
          download-file-name="gebaeude_nach_sektoren"
          :chart-data="sectorCount"
          :title="`Gebäude nach\nSektoren`"
          title-inside="Gebäude"
          :title-inside-l-h="20"
          class="pb-0"
        />
      </div>
    </div>
    <div class="data-row-dashboard">
      <div class="data-row-items grid-cols-1">
        <bar-chart-stacked
          :download-file-name="'baualtersklassen_nach_sektoren'"
          :chart-data="sectorByConstructionYear"
          title="Baualtersklassen nach Sektoren"
          style="min-height: 500px"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import BarChartStacked from '@/apps/analysis-dashboard/charts/barChartStacked.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

const constructionYearCount = computed(() => {
  return props.data ? props.data['construction_year_count'] : null;
});
const sectorByConstructionYear = computed(() => {
  const customOrder = [
    'Unbekannt',
    'nach 2001',
    '1969-2001',
    '1949-1968',
    'vor 1949',
  ];
  const sorted = Object.keys(props.data['sector_by_construction_year']).sort(
    (a, b) => {
      return customOrder.indexOf(a) - customOrder.indexOf(b);
    },
  );
  const sortedData = {};
  sorted.forEach((key) => {
    sortedData[key] = props.data['sector_by_construction_year'][key];
  });
  return sortedData;
});
const sectorCount = computed(() => {
  return props.data ? props.data['sector_count'] : null;
});
</script>
