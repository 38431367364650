<template>
  <div v-if="!loaded" class="p-3 pt-0 flex flex-col gap-2">
    <skeleton-loader border-radius="0" height="160px" />
    <skeleton-loader border-radius="0" height="90px" />
    <skeleton-loader border-radius="0" height="60px" />
  </div>
  <div
    v-else-if="loaded && (items.length || isSearchActive)"
    class="p-3 pt-0 flex flex-col gap-2"
  >
    <h5 class="text-infra-highlight-800">Kartenfilter</h5>
    <div>
      <slot />
    </div>
    <div class="border-b flex justify-between items-center gap-2">
      <h5 class="text-infra-highlight-800 mt-2">Vorhandene Wärmeprojekte</h5>
      <VIcon
        :class="{
          'animate-spin': animateSpin,
        }"
        class="text-[18px] cursor-pointer text-infra-highlight-600 hover:text-infra-highlight-800"
        data-test="update-projects"
        @click="fetchProjectSelectables"
        >mdi-refresh
      </VIcon>
    </div>
    <div class="relative">
      <InputEl
        v-model="searchQuery"
        placeholder="Namen eingeben"
        label="Projektgebietsuche"
        size="m"
      />
      <close-icon
        v-if="searchQuery"
        class="absolute right-1.5 top-5 w-5 cursor-pointer"
        @click="searchQuery = null"
      />
    </div>

    <div
      v-if="items.length"
      class="overflow-y-auto hide-scrollbar flex flex-col relative"
      :style="{
        maxHeight: containerHeight + 'px',
      }"
    >
      <CheckboxEl
        v-for="item in items"
        :key="item.id"
        :model-value="selected.includes(item.id)"
        :value="item.id"
        class="w-full hover:bg-infra-highlight-25 flex items-center gap-2 px-2.5 py-2"
        :class="{
          'opacity-60': item.progress === 2,
        }"
        :disabled="item.progress === 2"
        data-test="project-checkbox"
        @click="openFailDialog(item)"
        @update:model-value="item.progress === 0 && setSelectedItem(item.id)"
      >
        <div
          class="body-3 w-60 mt-[1px] break-words"
          :class="(item.scenario_updated || item.heatsource_updated) && 'w-44'"
        >
          {{ item.name }}
        </div>
        <div class="ml-auto flex items-center">
          <span
            v-if="item.scenario_updated || item.heatsource_updated"
            class="bg-red hover:bg-infra-highlight-500 rounded p-[3px] text-white body-2"
            @click="rerunFailedProject(item.id)"
            >veraltet
            <VTooltip activator="parent" location="start">
              <span v-if="item.scenario_updated">
                Szenario wurde aktualisiert<br
              /></span>
              <span v-if="item.heatsource_updated">
                Wärmequelle wurde aktualisiert<br
              /></span>
              (Hier klicken, um Berechnung erneut auszuführen)
            </VTooltip>
          </span>
          <IconWrapper
            class="ml-3"
            :icon="getIconSource(item.progress).icon"
            :fill="getIconSource(item.progress).fill"
            :data-test="getIconSource(item.progress).dataTest"
          />
        </div>
      </CheckboxEl>
      <ButtonEl
        :disabled="disableShowButton"
        text="Anzeigen"
        class="sticky bottom-0"
        @click="fetchProjects()"
      />
    </div>

    <div v-else class="body-2 pl-[1px]">Keine Ergebnisse</div>
  </div>

  <div v-else class="p-3 pt-0">
    <h5 class="text-infra-highlight-800">Keine Projektgebiete vorhanden.</h5>
  </div>

  <!--  progress: failed dialog -->
  <VuetifyDialog
    v-if="failedProject"
    v-model="failedDialog"
    :activator-full-width="false"
    :overflow-visible="true"
    :title="`Projektberechnung '${failedProject ? failedProject.name : '-'}' fehlgeschlagen`"
  >
    <template #content>
      Keine Sorge: Wir werden automatisch über jede fehlgeschlagene<br />
      Projektplanung informiert!<br /><br />
      Unser Kundenservice wird Sie benachrichtigen, sobald wir die Ursache
      <br />
      des Problems behoben haben. Ihre Eingangsdaten gehen nicht verloren<br />
      und Sie können die Projektplanung anschließend erneut starten.
    </template>
    <template #actions>
      <ButtonEl
        variant="secondary"
        text="Abbrechen"
        class="mr-auto"
        @click="closeFailedDialog"
      />
      <ButtonEl
        text="Löschen"
        color="error"
        variant="secondary"
        @click="deleteFailedProject"
      />
      <ButtonEl
        text="Erneut rechnen"
        variant="secondary"
        @click="rerunFailedProject(failedProject.id)"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { axios } from '@/utils/axiosHelper';
import { useStore } from 'vuex';
import {
  SWITCH_ID__HEAT_PROJECT_BUILDINGS,
  SWITCH_ID__HEAT_PROJECT_CENTROID,
  SWITCH_ID__HEAT_PROJECT_ESTATE,
  SWITCH_ID__HEAT_PROJECT_HEAT_SOURCES,
  SWITCH_ID__HEAT_PROJECT_NETWORK,
} from '@/configs/layer-panel/heatProject';
import cookie from 'vue-cookies';
import {
  LAYER_KEY__HEAT_PROJECT_CENTROID,
  LAYER_KEY__HEAT_PROJECT_ESTATE,
  LAYER_KEY__HEAT_PROJECT_NETWORK,
} from '@/configs/layers/heatProject';
import { highlightBuildings } from '@/features/heat-project/highlight-buildings';
import highlightHeatSources from '@/features/heat-project/highlight-heat-sources';
import SkeletonLoader from '@/components/storybook/src/stories/skeletonLoaderItem/skeletonLoader.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import CloseIcon from '@/components/storybook/src/assets/icons/close-icon.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import IconTaskProgressSharp from '@/components/storybook/src/assets/icons/custom/misc/IconTaskProgressSharp.vue';

const store = useStore();

const selected = ref([]);
const loaded = ref(false);
const searchQuery = ref(null);
const animateSpin = ref(false);
const containerHeight = computed(() => store.state.map.containerHeight - 335);

const items = computed(() => {
  return store.state.heatProject.heatProjectSelectables
    .filter((s) => s.scenario_id === scenarioSelected.value.scenario_id)
    .filter((item) => {
      // Include item if searchQuery is empty or if item.name includes the searchQuery
      return (
        !searchQuery.value ||
        item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });
});

const isSearchActive = computed(() => {
  return searchQuery.value !== null && searchQuery.value !== '';
});

function setSelectedItem(id) {
  const index = selected.value.findIndex((e) => e === id);
  if (index >= 0) selected.value.splice(index, 1);
  else selected.value.push(id);
  disableShowButton.value = false;
}

const disableShowButton = ref(true);

function fetchProjects() {
  updateProjectSwitchesAndData();
}

// Methods for switch state management
function changeProjectSwitches(active) {
  store.commit('map/CHANGE_SWITCH', {
    switches: [
      SWITCH_ID__HEAT_PROJECT_NETWORK,
      SWITCH_ID__HEAT_PROJECT_BUILDINGS,
      SWITCH_ID__HEAT_PROJECT_HEAT_SOURCES,
      SWITCH_ID__HEAT_PROJECT_CENTROID,
      SWITCH_ID__HEAT_PROJECT_ESTATE,
    ],
    active,
  });
}

function updateProjectSwitchesAndData() {
  store
    .dispatch('heatProject/GET_HEAT_PROJECT_MAP_DATA', selected.value)
    .then((resp) => {
      store.commit('map/SHOW_INFO_PANEL', false);
      changeProjectSwitches(selected.value.length > 0);
      loaded.value = true;
      store.commit('map/SET_SLIDER', true);
    });
}

onMounted(() => {
  fetchProjectSelectables();
});

function fetchProjectSelectables() {
  animateSpin.value = true;
  store.dispatch('heatProject/GET_HEAT_PROJECT_SELECTABLES').then(() => {
    loaded.value = true;
    setTimeout(() => {
      animateSpin.value = false;
    }, 725);
  });
}

// reset selections when scenario or municipality change
const scenarioSelected = computed(() => store.state.scenario.scenarioSelected);
watch(
  scenarioSelected,
  () => {
    changeProjectSwitches(false);
    store.commit('map/SET_LAYER_VIS', {
      onLayers: [
        LAYER_KEY__HEAT_PROJECT_CENTROID,
        LAYER_KEY__HEAT_PROJECT_NETWORK,
        LAYER_KEY__HEAT_PROJECT_ESTATE,
      ],
      active: false,
    });
    highlightBuildings(true);
    highlightHeatSources(false);
    searchQuery.value = null;
    store.state.heatProject.heatProjects = [];
    store.commit('map/SET_SLIDER', true);
    selected.value = [];
  },
  { deep: true },
);

// load project selected in map link of project overview table
const mapLoaded = computed(() => store.state.map.mapLoaded);
const loadProjectsOnMapLoad = computed(
  () => store.state.heatProject.loadProjectsOnMapLoad,
);
watch(
  [loadProjectsOnMapLoad, mapLoaded],
  () => {
    if (mapLoaded.value && loadProjectsOnMapLoad.value.length) {
      selected.value = loadProjectsOnMapLoad.value;
      updateProjectSwitchesAndData();
      store.commit('heatProject/SET_LOAD_PROJECTS_ON_MAPLOAD', []);
    }
  },
  { deep: true },
);

function getIconSource(progress) {
  switch (progress) {
    case 0: // abgeschlossen
      return {
        icon: 'task_alt',
        dataTest: 'task_alt',
        fill: 'text-green-500',
      };
    case 1: // abgebrochen
      return {
        icon: 'error_outline',
        dataTest: 'error_outline',
        fill: 'text-red-400',
      };
    case 2: // in bearbeitung
      return {
        icon: IconTaskProgressSharp,
        dataTest: 'IconTaskProgressSharp',
        fill: 'text-deep-orange-400',
      };
  }
}

// Rerun failed projects
const failedDialog = ref(false);
const failedProject = ref(null);

function openFailDialog(item) {
  if (item.progress === 1) {
    failedDialog.value = true;
    failedProject.value = item;
  }
}

function closeFailedDialog() {
  failedDialog.value = false;
}

function deleteFailedProject() {
  axios({
    url: `/api/heatprojects/HeatProject/${failedProject.value.id}/`,
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    fetchProjectSelectables();
    closeFailedDialog();
  });
}

function rerunFailedProject(projectId) {
  axios({
    url: `/api/heatprojects/rerun-project-planer/${projectId}/`,
    method: 'POST',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    fetchProjectSelectables();
    closeFailedDialog();
  });
}

watch(
  () => store.state.heatProject.heatProjects,
  (newProjects) => {
    if (newProjects.length > 0) {
      selected.value = newProjects.map((project) => project.id);
    }
  },
  { deep: true, immediate: true },
);
</script>
